import { Outlet, NavLink, Link } from "react-router-dom";

import oilogo from "../../assets/Outlook Insight Modern Technology-2.png";
import customerlogo from "../../assets/watech-logo-blue.png";

import styles from "./Layout.module.css";

import { useLogin } from "../../authConfig"

import { LoginButton } from "../../components/LoginButton"

const Layout = () => {
    return (
        <div className={styles.layout}>
            <header className={styles.header} role={"banner"}>
                <div className={styles.headerContainer}>
                    <Link to="https://www.outlookinsight.com" className={styles.headerTitleContainer}>
                        <img src={oilogo} aria-label="Link to Outlook Insight website" className={styles.outlookInsightLogo} />
                        
                    </Link>
                         <div className={styles.headerIndexUpdated}>
                            <h5 className={styles.chatEmptyStateIndexCount}>817 Pages Indexed<br/>Last Updated 11/20/2023</h5>
                        </div>
                    <Link to="https://watech.wa.gov" className={styles.headerTitleContainer}>
                        
                        <img src={customerlogo} aria-label="Link to WaTech website" className={styles.customerLogo} />
                    </Link>
                    {useLogin && <LoginButton/>}
                </div>
            </header>

            <Outlet />
        </div>
    );
};

export default Layout;
